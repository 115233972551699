@use "../global" as g;

main {
  padding-top: 80px;
  @include g.media(md) {
    padding-top: 91px;
  }
}

// section・背景
// main内のsection は 偶数毎に背景色追加
.sec {
  width: 100%;
  padding: 30px 0;

  &:last-child {
    margin-bottom: 30px;
  }

  &:nth-of-type(even) {
    background-color: g.$blue-light;
    padding: 40px 0;
  }

  @include g.media(md) {
    padding: 50px 0 60px;

    &:last-child {
      margin-bottom: 40px;
    }

    &:nth-of-type(even) {
      padding: 50px 0 60px;
    }
  }
}

.inner {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 0 4%;

  @include g.media(md) {
    padding: 0;
  }

  @include g.media(mdlg) {
    padding: 0 2%;
  }
}


// 余白
.mb-0 {
  margin-bottom: 0;
}


// 水色背景
.bg-blue {
  background-color: g.$blue-light;
}


// 下層ページメインイメージ 背景
.subpage-main {
  padding: 0 0 10px;

  @include g.media(md) {
    padding: 0 0 10px;
  }

  .inner {
    padding: 20px 0;
    text-align: center;
    background: linear-gradient(180deg, #F2F9F8 44.1%, rgba(242, 249, 248, 0) 100%);

    @include g.media(md) {
      padding: 38px 0;
    }
  }
}

// 下層ページメインイメージ h1
.subpage-ttl {
  margin-bottom: 4px;
  font-size: g.rem(24);
  color: g.$primary;
  line-height: 1.5;

  @include g.media(md) {
    font-size: g.rem(28);
  }
}

.subpage-ttl-line {
  img {
    width: 200px;
    margin: auto;

    @include g.media(md) {
      width: auto;
    }
  }
}


// 文字揃え
.txtcenter {
  text-align: center;
}


// ミッション青文字
.bold-txt {
  color: g.$primary;
  font-size: g.rem(16);
  font-weight: 600;

  @include g.media(md) {
    margin-bottom: 40px;
    font-size: g.rem(22);
  }
}


// リンクテキスト 詳しく見る
.blk-txt-link {
  margin-top: 20px;
  text-align: center;
}

.link-txt {
  @include g.linktxt;
  @include g.arrowright;
}


// リンクボタン
.btn {
  @include g.btn-wrap;
  a {
    @include g.btn();
  }
}

.btn-header {
  width: auto;
  a {
    padding: 9px 15px;
    font-size: g.rem(14);
    border-radius: 5px;
  
    @include g.media(md) {
      font-size: g.rem(16);
    }
  }
}


// タイトル
.sec-ttl {
  margin-bottom: 20px;
  font-size: g.rem(20);
  font-weight: 600;
  text-align: center;
  line-height: 1.5;

  @include g.media(md) {
    margin-bottom: 32px;
    font-size: g.rem(24);
  }
}

// ページ内リンク「link-」で始まるid名があるとき
.sec-ttl[id^=link-] {
  margin-top: -90px;
  padding-top: 90px;
  @include g.media(md){
    margin-top: -100px;
    padding-top: 100px;
  }
}

.ttl-lead {
  margin-top: -12px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5;

  @include g.media(md) {
    margin-top: -14px;
    margin-bottom: 30px;
  }
}

.blk-ttl {
  margin-bottom: 10px;
  font-size: g.rem(18);
  font-weight: normal;
  text-align: center;

  @include g.media(md) {
    margin-bottom: 15px;
    font-size: g.rem(20);
  }
}


// テキスト
.blk-txt {
  p {
    &:not(:last-of-type) {
      margin-bottom: 1.2em;
    }
  }
}


// モックアップ
// トップページ基盤
.mockup-list {
  @include g.media(md) {
    @include g.center;
    justify-content: center;
  }
}

.mockup-item {
  margin-bottom: 30px;

  @include g.media(md) {
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
}

.mockup-logo {
  img {
    margin: auto;
  }

  &.mockup-kaigo {
    img {
      width: 110px;
      @include g.media(md) {
        width: 100px;
      }
    }
  }

  &.mockup-smile {
    img {
      width: 153px;
      @include g.media(md) {
        width: 143px;
      }
    }
  }
}

.mockup-screen {
  img {
    width: 100%;
    max-width: 360px;
    margin: auto;
  }
}


// 吹き出し
.balloon {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  background: g.$blue-light;
  border-radius: 10px;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 30px solid g.$blue-light;
    border-left: 16px solid transparent;
    border-right: 10px solid transparent;
    transform: rotate(35deg);
    position: absolute;
    margin: 0;
    bottom: -20px;
    left: 20px;
  }
}


// Google Map
.blk-gmap {
  @include g.rwd-iframe(600, 450);
}