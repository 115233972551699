@use "../global" as g;

// dl
.detail-list {
  width: 100%;
  border: 1px solid g.$gray-medium;
  border-bottom: none;

  @include g.media(md) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
}

.detail-list dt {
  width: 100%;
  padding: 10px;
  font-weight: 600;
  background: g.$gray-light;
  border-right: 1px solid g.$gray-medium;
  border-bottom: 1px solid g.$gray-medium;

  @include g.media(md) {
    @include g.row-center;
    width: 240px;
    padding: 15px 10px 15px 20px;
    border-right: 1px solid g.$gray-medium;
    border-bottom: 1px solid g.$gray-medium;
  }
}

.detail-list dd {
  width: 100%;
  padding: 10px;
  background: g.$white;
  border-bottom: 1px solid g.$gray-medium;

  @include g.media(md) {
    width: calc(100% - 240px);
    padding: 15px 10px;
    border-bottom: 1px solid g.$gray-medium;
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  em {
    font-weight: 600;
    font-style: normal;
  }
}

// リスト表示
.detail-list {
  ul {
    margin-top: 5px;
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }

  .detail-list-txtlist {
    li {
      &:not(:last-of-type) {
        margin-bottom: 1.2em;
      }
    }
  }
}


// ul list ・あり
.detail-list-lists {
  li {
    @include g.indent;
  }
}

.detail-list-diamond {
  li {
    @include g.indent;

    &::before {
      content: "◇";
    }
  }
}


// table
table {
  width: 100%;
  margin: 0 0 20px;

  th, td {
    padding: 10px;
    font-weight: normal;
    text-align: left;
    line-height: 1.6;
    border: 1px solid g.$gray-medium;
    background: g.$white;
  }

  .table-bg {
    background: g.$gray-light;
  }

  ul.list-disc {
    padding-left: 0;
  }

  dt, dd {
    display: inline;
    margin-left: 0px;
  }
}
