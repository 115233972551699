@use '../settings/functions' as f;
@use '../settings/config' as c;
@use '../mixin/icon' as icon;


// flex 中央揃え
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin row-center {
  display: flex;
  align-items: center;
}

@mixin col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


// z-index（値は_config.scss）
@mixin z-index($key) {
  z-index: map-get(c.$layer, $key);
}
// 使用時：@include z-index(header);


// テキストリンク
@mixin linktxt {
  display: inline-block;
  padding: 10px 2%;
  color: c.$primary;
  font-weight: bold;
}


// 右矢印
@mixin arrowright {
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 0 0 7px;
    border-top: solid 2px c.$primary;
    border-right: solid 2px c.$primary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}


// リンクボタン
@mixin btn-wrap {
  width: 100%;
  max-width: 320px;
  margin: auto;
}
@mixin btn($color: c.$primary) {
  display: block;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 10px;
  font-size: f.rem(16);
  font-weight: 600;
  color: c.$white;
  text-align: center;
  text-decoration: none;
  border: 1px solid $color;
  border-radius: 10px;
  background-color: $color;
  box-shadow: 0px 1px 3px rgba(8, 96, 92, 0.3);

  @include c.media(md) {
    padding: 10px 15px;
    font-size: f.rem(20);
  }

  &:hover {
    opacity: 0.9;
  }
}


// ulの・などのインデント
@mixin indent {
  padding-left: 1.2em;
  position: relative;

  &::before {
    content: "・";
    display: inline-block;
    width: 1.2em;
    position: absolute;
    left: 0;
  }
}


// GoogleMapレスポンシブ
@mixin rwd-iframe($width: 4, $height: 3) {
  position: relative;
  padding-top: percentage(calc($height / $width));

  >iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
// 使用時：@include rwd-iframe(640, 360);