@use "../global" as g;

// 共通・サービス利用規約
.policy-all {

  .mb-0 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: g.rem(18);
  }

  h4 {
    margin: 10px 0 5px;
    font-size: inherit;
    font-weight: normal;
  }

  ol,
  ul {
    margin: 10px 0 20px;

    li {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .list-number-none {
    padding-left: 1em;
    
    li {
      list-style: none;
    }

    @include g.media(md) {
      padding-left: 20px;
    }
  }

  .list-disc {
    padding-left: 1em;

    li {
      @include g.indent;
    }

    > ol {
      li {
        list-style: decimal;
      }
    }
    .list-number-none {
      li {
        &:before {
          display: none;
        }
      }
    }
  }

  // 制定日
  .enactment-date {
    text-align: right;
  }

  // 署名
  .blk-txt-right {
    margin: 0;
    text-align: right;

    p {
      margin: 0;
    }
  } 
}


// 表
.policy-table {
  tr {
    th, td {
      &:first-child {
        width: 28%;
      }
    }
  }
  ul, .list-disc, ol, .list-number-none {
    margin: 10px 0;
    padding-left: 0;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}