@use "../global" as g;

.footer {
  .f-inner-item {
    padding: 20px 0;
    background-color: g.$gray-light;

    @include g.media(md) {
      padding: 36px 0 46px;
    }
  }

  .inner {
    @include g.media(md) {
      @include g.center;
    }
  }
}

.f-logo {
  margin-bottom: 20px;

  img {
    width: 80px;
  }

  @include g.media(md) {
    margin-right: 68px;
    margin-bottom: 0;

    img {
      width: 136px;
    }
  }
}

.f-nav {
  width: 100%;

  @include g.media(md) {
    width: calc(100% - 136px - 68px);
    max-width: 600px;
  }
}

.f-nav-list {
  @include g.row-center;
  flex-wrap: wrap;
  margin-top: -12px;
  margin-left: -20px;
}

.f-nav-item {
  margin: 12px 0 0 20px;

  @include g.media(md) {
    font-size: g.rem(14);
  }

  a {
    color: g.$black;
  }
}


.f-nav-list-bold {
  margin-bottom: 12px;

  @include g.media(md) {
    margin-bottom: 20px;
    margin-left: -40px;
  }

  .f-nav-item {
    font-weight: 600;

    @include g.media(md) {
      margin-left: 40px;
    }
  }
}

.txt_copyright {
  width: 100%;
  padding: 5px 0;
  font-size: g.rem(12);
  text-align: center;

  @include g.media(md) {
    padding: 10px 0;
  }
}