@use './config' as c;

// remをpx感覚で使用
$baseFontSize: 16;
html {
  font-size: $baseFontSize + px;
}
@function rem($pixels, $context: $baseFontSize) {
  @return calc($pixels / $context) * 1rem;
}
// 使用時：font-size: rem(18);
// margin-bottom: rem(18);
