@use '../settings/config' as c;
@use '../settings/functions' as f;

body {
  color: c.$black;
  font-family: "Hiragino Kaku Gothic ProN", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Arial", "Meiryo", "Yu Gothic", sans-serif;
  font-size: f.rem(15);
  line-height: 1.8;

  @include c.media(md) {
    width: 100%;
    font-size: f.rem(16);
  }
}

// 表示非表示
.visible-md {
  display: none;
}

@include c.media(md) {
  .visible-xs {
    display: none;
  }

  .visible-md {
    display: block;
  }
}

a {
  color: c.$primary;
}

img, picture {
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

ol {
  margin: 0;
  padding-left: 1.2em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}