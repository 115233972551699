@use "../global" as g;

.header {
  padding: 5px 4% 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include g.z-index(header);
  
  .inner {
    @include g.center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(161, 174, 174, 0.35);
    border-radius: 5px;
  }

  @include g.media(md) {
    padding: 10px 10px 15px;
    .inner {
      padding: 8px 20px;
    }
  }

  @include g.media(mdlg) {
    padding: 10px 2% 15px;
  }
}


.h-logo {
  width: 80px;
  @include g.media(md) {
    width: 110px;
  }
}

.h-nav-list {
  @include g.media(md) {
    @include g.center;
  }
}

.h-nav-item {
  height: 100%;
  a {
    display: block;
    padding: 0px 2px;
    font-weight: 600;
    @include g.media(md) {
      padding: 10px 20px;
    }
  }
  &:nth-last-child(2) a {
    padding-right: 5px;
    @include g.media(md) {
      padding-right: 40px;
    }
  }
  &:not(:last-child) a {
    color: g.$black;
    text-decoration: none;
  }
}

// ハンバーガーメニュー
.area-menu {
  @include g.row-center;
  .btn-header {
    margin-right: 8px;
  }
}

.toggle-open {
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  @include g.z-index(toggle);
  cursor: pointer;
}
.toggle-hidden {
  display: none;
}

// メニューアイコン 三本線
.toggle-open span,
.toggle-open span:before,
.toggle-open span:after {
  content: '';
  display: block;
  height: 3px;
  width: 28px;
  border-radius: 3px;
  background: g.$black;
  transition: 0.3s;
  position: absolute;
}

.toggle-open span:before {
  bottom: 8px;
}

.toggle-open span:after {
  top: 8px;
}

// クリックされたら
// 棒の回転・透明化
#togglenav:checked ~ .toggle-open span {
  background: rgba(255, 255, 255, 0);
}

#togglenav:checked ~ .toggle-open span::before {
  bottom: 0;
  transform: rotate(45deg);
}

#togglenav:checked ~ .toggle-open span::after {
  top: 0;
  transform: rotate(-45deg);
}

// 黒背景の表示
#togglenav:checked ~ .togglenav-bg {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

// 黒背景 設定
.togglenav-bg {
  cursor: pointer;
  position: fixed;
  top: 0px;
  right: 0px;
  pointer-events: none;
  @include g.z-index(default);
  width: 100%;
  height: 100%;
  transition: background-color .6s;
}

// ドロワーメニュー
#togglenav:checked ~ .menu-drawer {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.menu-drawer {
  width: 70%;
  height: 100%;
  padding-top: 50px;
  background-color: g.$white;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform .2s;
  transition: transform .2s;
  position: fixed;
  top: 0;
  right: 0;
  @include g.z-index(menu-drawer);
  overflow-y: scroll;

  .h-nav-item {
    border-bottom: solid 1px g.$gray-medium;

    a {
      padding: 10px 12px;
      @include g.arrowright;
      text-decoration: none;
      &::after {
        right: 12px;
      }
    }

    &:last-child {
      a {
        color: g.$black;
      }
    }
  }
}
