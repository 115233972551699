// Color
$primary: #009E96;
$secondary: #ED6C00;
$blue-light: #F2F9F8;
$white: #fff;
$black: #2E3333;
$white: #ffffff;
$gray-light: #F5F5F5;
$gray-medium: #cccccc;

// z-index
$layer: (
  toggle       : 20,
  menu-drawer  : 10,
  header       : 30,
  default      : 1
);

// ブレイクポイント
$breakpoints: (
  xs: "only screen and (min-width: 320px)",
  sm: "only screen and (min-width: 576px)",
  md: "only screen and (min-width: 768px)",
  lg: "only screen and (min-width: 992px)",
  mdlg: "only screen and (min-width: 768px) and (max-width: 1040px)",
  xl: "only screen and (min-width: 1200px)",
  xxl: "only screen and (min-width: 1400px)"
) !default;
@mixin media($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint ) {
    @media #{map-get($breakpoints, $breakpoint)}{
      @content;
    }
  }
  @else {
    @error "指定されたブレークポイントは定義されていません。" + "指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  } 
}
// 使用方法：@include 名前空間.media(md){}